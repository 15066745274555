@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #ef1927;
  --color-secondary: #fcd9dc;
  --color-tint: #ab2129;
  --color-blue: #002775;
  --color-grey: #8d8d8d;
  --color-bg-blue: #f7fbfe;
  --color-grey-light: #e6e6e6;
  --color-grey-super-light: #fafafa;
}

td,
th {
  padding: 0 15px; /* Adjust the 10px value to the desired spacing */
}

.bold {
  font-weight: 700;
}

h1 {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 62px;
}

h2 {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
}

h3 {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 37px;
  color: var(--color-primary);
}

h4 {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
}

.primary {
  color: var(--color-primary);
}

body {
  background-color: white;
  margin: 0; /* Reset margin to ensure no unwanted spacing */
  padding: 0; /* Reset padding to ensure no unwanted spacing */
}

/* If you have a root container, apply the background color to it as well */
#root {
  background-color: white;
}
